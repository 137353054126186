// JAVA api
import service from '@/utils/request'

// 获取年度报告数据
export function getReportData(id) {
  return service({
    url: '/bookstore/app/api/2022/yearlyreport',
    method: 'POST',
    data: { id }
  })
}
