import axios from 'axios'
import { Notify } from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000, // request timeout
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

service.interceptors.request.use(config => {
  if (config.method === 'post') {
    config.data = JSON.stringify(config.data)
  }
  return config
},
error => {
  return Promise.reject(error)
}
)

service.interceptors.response.use(response => {
  const res = response.data
  console.log(response)
  if (res.code !== 200) {
    Notify({ type: 'warning', message: res.msg || '接口报错' })
    return Promise.reject(res)
  } else {
    return res
  }
},
error => {
  console.log('err' + error) // for debug
  return Promise.reject(error)
}
)

export default service
