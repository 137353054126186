import Vue from 'vue'
import Vuex from 'vuex'
import { startCP } from "@/api/api"
import storage from 'store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  
  },
  getters: {
  },
  mutations: {
 
  },
  actions: {
   
  },
  modules: {
  }
})
