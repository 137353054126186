<template>
  <div class="btn" @click="action">
    <img src="@/assets/images/next.png" alt="" />
  </div>
</template>

<script>
export default {
  methods: {
    action() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  z-index: 100;
}
</style>
