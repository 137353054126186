import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/components.css'
import "swiper/swiper.min.css"
import './style/comm.css'
import './utils/vant.js'

import NextButton from './components/NextButton.vue'
Vue.config.productionTip = false
Vue.component("next-button", NextButton);
Vue.prototype.$imageUrl= process.env.VUE_APP_IMAGE_URL;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
